.creators {
	&__header {
		margin-bottom: 83px;
		@include tablet {
			max-width: 398px;
			margin: 0 auto 73px;
		}

		@include desktop {
			max-width: 640px;
			margin-bottom: 122px;
		}
	}

	&__counters {
		padding-top: 96px;
		padding-bottom: 130px;

		@include tablet {
			padding-top: 65px;
			padding-bottom: 88px;
		}

		@include desktop {
			padding-top: 87px;
			padding-bottom: 112px;
		}
	}

	&__back {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 76px;

		@include tablet {
			margin-bottom: 84px;
			margin-left: initial;
			margin-right: initial;
		}

		@include desktop {
			margin-bottom: 104px;
		}
	}

	&-inner {
		&__counters {
			margin-bottom: 140px;

			@include desktop {
				max-width: 747px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 200px;
			}
		}

		&__socials {
			margin-bottom: 130px;
			@include tablet {
				max-width: 375px;
				margin-left: auto;
				margin-right: auto;
			}

			@include desktop {
				max-width: none;
				margin-bottom: 200px;
			}
		}

		&__name {
			margin-bottom: 6px;
			font-size: 41px;
			font-weight: 700;
			line-height: 47px;
		}

		&__info {
			margin-bottom: 30px;

			@include tablet {
				display: none;
			}
		}

		&__col {
			display: flex;
			flex-direction: column;
			grid-gap: 1rem;
			margin-bottom: 1rem;

			.creators-inner__info {
				display: none;
			}

			@include tablet {
				&:nth-child(1) {
					margin-top: 210px;
				}
			}

			@include desktop {
				&:nth-child(1) {
					margin-top: 60px;
				}
			}
		}

		&__gallery {
			margin-bottom: 70px;

			@include tablet {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 1rem;
				margin-bottom: 169px;
			}

			.creators-inner__info {
				display: block;

				@include desktop {
					margin-left: 3rem;
				}
			}
		}

		&__image {
			display: block;
			border-radius: 40px;
			overflow: hidden;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}

			&:nth-child(odd) {
				height: 279px;
			}

			&:nth-child(even) {
				height: 552px;
			}

			@include desktop {
				&:nth-child(odd) {
					height: 924px;
				}

				&:nth-child(even) {
					height: 705px;
				}
			}
		}
	}

	&-partners {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		row-gap: 56px;
		margin-bottom: 80px;

		@include tablet {
			column-gap: 60px;
			row-gap: 80px;
		}

		@include desktop {
			column-gap: 167px;
			row-gap: 141px;
			margin-bottom: 162px;
		}
	}

	&-highlight {
		&__header {
			margin-bottom: 109px;

			@include tablet {
				margin-bottom: 90px;
			}
		}

		&__grid {
			margin-bottom: 85px;
			display: none;

			@include tablet {
				margin-bottom: 109px;
				display: grid;
			}

			@include desktop {
				margin-bottom: 159px;
			}
		}

		&__slider {
			margin-bottom: 85px;
			@include tablet {
				display: none;
			}
		}
	}
}

.search {
	&-form {
		position: relative;
		width: 100%;
		margin-bottom: 57px;
		@include tablet {
			width: 349px;
			margin: 0 auto 75px;
		}

		@include desktop {
			width: 422px;
			margin-bottom: 123px;
		}
	}

	&-group {
		position: relative;
	}

	&-input {
		width: 100%;
		padding: 14px 60px 14px 20px;
		border-radius: 57px;
		border: 2px solid transparent;
		overflow: hidden;
		box-sizing: border-box;
		background-image: linear-gradient(
			320deg,
			#e8b7e5 0%,
			#a25fd5 50%,
			#6bbbf5 100%
		);
		background-origin: border-box;
		box-shadow: inset 0 1000px white;
		outline: none;
	}

	&-submit {
		position: absolute;
		top: 0;
		right: 22px;
		bottom: 0;
		width: 31px;
		height: 31px;
		margin: auto;
		padding: 0;
		border: none;
		background: transparent;
	}

	&-filters {
		margin-top: 8px;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
}

.grid {
	&-item {
		float: left;
		border-bottom: 1rem solid transparent;

		@include tablet {
			border-right: 1rem solid transparent;

			&:nth-child(2) {
				.creators-block {
					margin-top: 50px;
				}
			}
		}
	}

	&-item,
	&-sizer {
		width: 100%;
		@include tablet {
			width: 50%;
		}

		@include desktop {
			width: 33.3%;
		}
	}
}
