.t-hero {
	&--bg {
		position: relative;
		&::after {
			content: "";
			position: absolute;
			width: 577px;
			height: 328px;
			top: 112px;
			left: -534px;
			border-radius: 40px;
			background: linear-gradient(
				360deg,
				#e8b7e5 0%,
				#a25fd5 48.93%,
				#6bbbf5 100%
			);

			@include tablet {
				height: 424px;
			}

			@include desktop {
				top: 120px;
				height: 543px;
			}
		}

		&::before {
			content: "";
			position: absolute;
			top: -49px;
			left: -68px;
			width: 291px;
			height: 417px;
			border-radius: 40px;
			background: rgba($color: #e0b9e2, $alpha: 0.05);

			@include tablet {
				display: none;
			}
		}
	}

	&__header {
		margin-bottom: 100px;
		padding: 256px 1rem 80px;
		border-bottom-left-radius: 40px;
		border-bottom-right-radius: 40px;
		background-color: rgba($color: #e0b9e2, $alpha: 0.1);

		@include tablet {
			padding: 192px 89px 80px;
		}

		@include desktop {
			padding: 272px 0 100px;
		}
	}

	&__wrapper {
		@include desktop {
			padding-left: 109px;
			padding-right: 109px;
		}
	}

	&__title {
		font-size: 66px;
		font-weight: 700;
		line-height: 75px;

		&:not(:last-child) {
			margin-bottom: 24px;
		}
	}

	&__block {
		margin-top: 60px;

		@include desktop {
			margin-top: 100px;
			display: grid;
			grid-template-columns: 33% 1fr;
			grid-gap: 100px;
			align-items: flex-start;
		}
	}

	&__sidebar {
		position: relative;
		margin-bottom: 80px;
		padding-bottom: 80px;
		max-width: 191px;
		margin-left: auto;
		margin-right: auto;

		@include tablet {
			max-width: none;
			margin-left: unset;
			margin-right: unset;
		}

		@include desktop {
			margin-bottom: 0;
			padding-bottom: 0;
		}

		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 9px;
			border-radius: 10px;
			background: #e0b9e2;

			@include desktop {
				left: unset;
				right: 0;
				width: 9px;
				height: 100%;
			}
		}

		&-block {
			&:not(:last-child) {
				margin-bottom: 42px;
			}
		}

		&-title {
			font-size: 16px;
			font-weight: 400;
			line-height: 30px;
		}

		&-subtitle {
			font-size: 20px;
			font-weight: 600;
			line-height: 23px;
		}
	}

	&__info {
		&-block {
			&:not(:last-child) {
				margin-bottom: 40px;
			}
		}
	}

	&__counters {
		margin-bottom: 100px;

		@include desktop {
			margin-bottom: 154px;
			margin-left: auto;
			margin-right: auto;
			max-width: 760px;
		}
	}

	&__gallerys {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 1rem;
		margin-bottom: 150px;
		@include tablet {
			margin-bottom: 120px;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, 1fr);
		}
		@include desktop {
			margin-bottom: 140px;
		}
	}

	&__gallery {
		display: block;
		height: 544px;
		border-radius: 40px;
		overflow: hidden;
		&-image {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		@include tablet {
			&:nth-child(3) {
				grid-column: 1/3;
			}
		}

		@include desktop {
			height: auto;
			&:nth-child(1) {
				grid-row: 1/3;
			}

			&:nth-child(3) {
				grid-column: auto;
			}

			&:nth-child(4) {
				grid-column: 1/3;
			}
		}
	}

	&__accardion {
		margin-bottom: 144px;
		@include tablet {
			margin-bottom: 190px;
		}

		@include desktop {
			margin-bottom: 200px;
		}

		@include active {
			.t-hero__accardion-icon {
				&::after {
					opacity: 0;
					transform: rotate(0);
				}
			}
		}

		&-header {
			position: relative;
			padding-bottom: 27px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 9px;
				border-radius: 10px;
				background: #e0b9e2;
			}
		}

		&-icon {
			display: block;
			width: 32px;
			height: 32px;
			position: relative;

			&::after,
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 3px;
				margin: auto;
				border-radius: 50px;
				background: linear-gradient(
					315deg,
					#e8b7e5 0%,
					#a25fd5 48.93%,
					#6bbbf5 100%
				);
				transition: all 0.3s ease-in-out;
			}

			&::after {
				transform: rotate(90deg);
			}
		}

		&-body {
			margin-top: 63px;
			display: none;

			@include tablet {
				margin-top: 89px;
			}

			@include desktop {
				margin-top: 80px;
			}
		}

		&-content {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 70px;

			@include tablet {
				grid-template-columns: repeat(2, 1fr);
			}

			@include desktop {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}
