.about-section {
	padding-top: 88px;
	padding-bottom: 62px;

	@include tablet {
		padding-top: 101px;
		padding-bottom: 68px;
	}

	@include desktop {
		padding-top: 80px;
		padding-bottom: 77px;
	}

	&__wrapper {
		@include tablet {
			max-width: 70%;
			margin: 0 auto;
		}

		@include desktop {
			max-width: 42%;
		}
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 2rem 1rem;
		margin-top: 50px;

		@include desktop {
			margin-top: 93px;
			gap: 2rem 4rem;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__icon {
		width: 64px;
		height: 64px;
		flex: 0 0 64px;
	}

	&__name {
		font-size: 18px;
		font-weight: 600;
		line-height: 23px;
		text-align: center;
		color: #6d6d6d;
	}
}

.work-section {
	margin-top: 62px;
	margin-bottom: 50px;
	position: relative;

	@include tablet {
		margin-top: 68px;
	}

	@include desktop {
		margin-top: 77px;
		margin-bottom: 88px;
		min-height: 406px;
	}

	.container {
		position: unset;
	}

	&__block {
		margin-bottom: 90px;
		text-align: center;

		@include tablet {
			margin-bottom: 124px;
		}

		@include desktop {
			padding-top: 38px;
			width: 50%;
			text-align: right;
		}
	}

	&__button {
		margin-top: 40px;
		display: flex;
		justify-content: center;

		@include desktop {
			justify-content: flex-end;
		}
	}

	&__slider {
		@include desktop {
			position: absolute;
			top: 0;
			right: 0;
			width: calc(50% - 125px);
		}

		@media (min-width: 1800px) {
			.swiper-slide {
				width: auto;
			}
		}
	}

	&__slide {
		display: block;
		position: relative;

		img {
			display: block;
			width: 100%;
			height: auto;
			object-fit: cover;
			object-position: center;
		}
	}

	.swiper-pagination {
		@include desktop {
			display: none;
		}
	}
}

.partners-section {
	margin-top: 100px;
	margin-bottom: 90px;
	@include tablet {
		margin-bottom: 130px;
	}
	@include desktop {
		margin-top: 176px;
		margin-bottom: 160px;
	}
	&__block {
		@include tablet {
			max-width: 530px;
			margin: 0 auto;
		}

		@include desktop {
			max-width: 858px;
		}
	}

	&__items {
		margin-top: 137px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 30px;
		align-items: center;
		justify-items: center;

		@include tablet {
			grid-template-columns: repeat(3, 1fr);
		}

		@include desktop {
			grid-template-columns: repeat(5, 1fr);
			grid-gap: 70px;
		}
	}
}

.creators-section {
	margin-top: 90px;
	margin-bottom: 50px;

	@include tablet {
		margin-top: 130px;
	}

	@include desktop {
		margin-top: 160px;
		margin-bottom: 160px;
	}
	&__block {
		@include tablet {
			max-width: 530px;
			margin: 0 auto;
		}

		@include desktop {
			max-width: 640px;
		}
	}

	&__button {
		margin-top: 40px;
		display: flex;
		justify-content: center;
	}

	&__slider {
		margin-top: 73px;
		position: relative;

		@include tablet {
			margin-top: 56px;
		}

		@include desktop {
			margin-top: 65px;
		}

		.swiper-button-prev,
		.swiper-button-next {
			display: none;

			@include desktop {
				display: block;
			}
		}

		.creators-block {
			height: 600px;

			&__image {
				width: 100%;
				height: 100%;
			}
		}
	}
}

.service-section {
	margin-top: 50px;
	margin-bottom: 70px;

	@include tablet {
		margin-top: 70px;
		margin-bottom: 134px;
	}

	@include desktop {
		margin-top: 160px;
		margin-bottom: 150px;
	}

	&__wrapper {
		@include desktop {
			display: flex;
			align-items: center;
			grid-gap: 125px;
			flex-direction: row-reverse;
		}
	}

	&__block {
		margin-bottom: 54px;
		text-align: center;

		@include desktop {
			margin-bottom: 0;
			max-width: 39.4%;
			flex: 0 0 39.4%;
			text-align: left;
		}
	}

	&__slider {
		@include desktop {
			max-width: 50.6%;
			flex: 0 0 50.6%;
		}
	}

	&__image {
		width: 100%;
		height: 398px;
		border-radius: 40px;
		overflow: hidden;
		background-color: #e0b9e2;

		@include tablet {
			height: 348px;
		}

		@include desktop {
			height: 312px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.swiper-pagination {
		@include tablet {
			display: none;
		}
	}
}

.contact-section {
	margin: 100px 0;

	@include tablet {
		margin-top: 134px;
		margin-bottom: 219px;
	}

	@include desktop {
		margin-top: 150px;
		margin-bottom: 108px;
	}
}
