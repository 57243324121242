.hero {
	margin: 16px 16px 28px;
	padding-top: 152px;
	padding-bottom: 36px;
	position: relative;
	border-radius: 20px;
	overflow: hidden;
	background: linear-gradient(
		315deg,
		#e8b7e5 0%,
		#a25fd5 48.93%,
		#6bbbf5 100%
	);

	@include tablet {
		border-radius: 40px;
		margin-bottom: 131px;
		padding-top: 264px;
		padding-bottom: 206px;
	}

	@include desktop {
		margin-bottom: 116px;
		min-height: 968px;
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;
	}

	&__title {
		margin: 0;
		font-weight: 700;
		font-size: 40px;
		line-height: 50px;
		color: #fff;
		word-wrap: break-word;
		text-transform: uppercase;

		&:not(:last-child) {
			margin-bottom: 24px;
		}

		@include tablet {
			font-size: 66px;
			line-height: 75px;
		}
	}

	&__subtitle {
		font-weight: 400;
		font-size: 16px;
		line-height: 30px;
		color: #fff;
	}

	&__button {
		margin-top: 24px;
	}

	&__block {
		@include tablet {
			max-width: 81%;
		}

		@include desktop {
			max-width: 640px;
			margin-left: 70px;
		}
	}

	&__images {
		position: absolute;
		top: 85px;
		right: -430px;
		width: 794.5px;
		height: 963px;
		display: none;

		@include tablet {
			display: block;
		}

		@include desktop {
			right: -35px;
		}
	}

	&--notBg {
		min-height: 776px;
		overflow: initial;

		@include tablet {
			min-height: 968px;
		}
	}

	&--small {
		.hero {
			margin-bottom: 40px;
		}
	}
}
