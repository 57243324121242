.counters {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-around;

	@include desktop {
		flex-direction: row;
		gap: 48px 80px;
	}

	&__block {
		position: relative;
		text-align: center;

		&:not(:last-child) {
			margin-bottom: 60px;
		}

		@include desktop {
			&:not(:last-child) {
				margin-bottom: 0;
			}
		}
	}

	&__text {
		margin-bottom: 6px;
		font-size: 13px;
		font-weight: 400;
		line-height: 20px;
		color: rgba($color: #000000, $alpha: 0.4);
	}

	&__counter {
		font-size: 41px;
		font-weight: 700;
		line-height: 47px;
		color: #000;
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 8px;
			background: #e0b9e2;
			border-radius: 10px;
		}
	}
}
