.custom-select {
	&-container {
		position: relative;
		select {
			visibility: hidden;
			position: absolute;
			top: 0;
			left: 0;
		}

		&.is-open {
			.custom-select-panel {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;
			}
		}
	}

	&-opener {
		padding: 8px 24px;
		position: relative;
		display: flex;
		align-items: center;
		font-family: $font-secondary;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #9962ce;
		cursor: pointer;

		&::after {
			content: "";
			display: block;
			margin-left: 8px;
			width: 14px;
			height: 9px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1IDEuNjQxNDhMOCA4LjM1ODU1TDEgMS42NDE0OCIgc3Ryb2tlPSIjOTk2MkNFIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
			background-size: contain;
			background-repeat: no-repeat;
		}
	}

	&-panel {
		position: absolute;
		top: 100%;
		left: -10px;
		padding: 20px;
		max-width: 200px;
		max-height: 500px;
		width: max-content;
		overflow-x: hidden;
		overflow-y: auto;
		border-radius: 10px;
		background: #fff;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
		opacity: 0;
		visibility: hidden;
		transform: translateY(-10px);
		z-index: 10;
		transition: all 0.3s ease-in-out;
		will-change: transform, opacity;
	}

	&-option {
		display: block;
		padding: 5px 0;
		font-size: 16px;
		line-height: 30px;
		color: #000;
		cursor: pointer;
		transition: color 0.3s ease-in-out;

		@include hover {
			color: #9962ce;
		}

		&.is-selected {
			color: #9962ce;
			opacity: 0.8;
			pointer-events: none;
		}
	}
}
