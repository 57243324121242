*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	position: relative;
	overflow-x: hidden;
	min-height: 100vh;
	box-sizing: border-box;
	font-family: $font-primary;
	font-size: 16px;
	scroll-behavior: smooth;

	&.has-cursor,
	&.has-cursor * {
		// stylelint-disable-next-line
		cursor: none !important;
	}

	&.is-lock-scroll,
	&.is-lock-scroll body {
		overflow: hidden;
	}
}

body {
	-webkit-font-smoothing: antialiased;
	text-decoration-skip: objects;
	text-rendering: optimizeLegibility;
	text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
}

main {
	flex: 1;
}

p {
	margin: 0;
	font-size: 16px;
	line-height: 30px;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}

a,
button {
	outline: none;
	transition: $duration-normal;
	cursor: pointer;
}

a {
	text-decoration: none;
	color: #9962ce;
}

svg {
	display: block;
	flex: 0 0 auto;
	width: 100%;
	height: 100%;
	fill: currentColor;
}

figure,
picture {
	display: inline-block;
	margin: 0;
	line-height: 0;

	img {
		width: 100%;
	}
}

img {
	vertical-align: top;
	max-width: 100%;
	user-select: none;
}

img[draggable="false"] {
	pointer-events: none;
	// stylelint-disable-next-line
	-webkit-user-drag: none;
}

fieldset {
	margin: 0;
	border: 0;
	padding: 0;
}

ul,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}

video {
	outline: none;
	width: 100%;
	height: 100%;
}

iframe {
	display: block;
}

.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	border: 0;
	padding: 0;
	width: max-content;
	padding: 14px 60px;
	font-family: $font-secondary;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #f9fafb;
	border-radius: 100px;
	text-align: center;
	background: linear-gradient(
		315deg,
		#e8b7e5 0%,
		#a25fd5 48.93%,
		#6bbbf5 100%
	);
	transition: $duration-normal;
	user-select: none;
	box-shadow: 0px 2px 4px 0px rgba(52, 60, 68, 0.16),
		0px 0px 1px 0px rgba(117, 131, 142, 0.04);

	@include hover {
		box-shadow: 0px 12px 24px 0px rgba(29, 78, 216, 0.32),
			0px 2px 4px 0px rgba(59, 130, 246, 0.12);
	}

	&:active {
		box-shadow: -5px -5px 10px 0px #fff, 5px 5px 10px 0px #bebebe;
	}

	&-hero {
		border: 4px solid var(--white, #fff);
	}

	&-secondary {
		position: relative;
		padding: 18px 47px;
		color: #9962ce;
		background: transparent;
		box-shadow: none;

		&::before {
			content: "";
			position: absolute;
			inset: 0;
			border-radius: 100px;
			padding: 2px;
			background: linear-gradient(
				315deg,
				#e8b7e5 0%,
				#a25fd5 48.93%,
				#6bbbf5 100%
			);
			-webkit-mask: linear-gradient(#fff 0 0) content-box,
				linear-gradient(#fff 0 0);
			-webkit-mask-composite: xor;
			mask-composite: exclude;
		}

		@include hover {
			text-shadow: 0 0 15px rgba(255, 255, 255, 0.5),
				0 0 10px rgba(255, 255, 255, 0.5),
				0px 7px 11px rgba(153, 98, 206, 0.42);
			box-shadow: none;
		}
	}

	&-arrow-left {
		svg {
			display: inline-block;
			margin-right: 8px;
			width: auto;
			height: auto;
			fill: none;
		}
	}

	&-arrow-right {
		svg {
			display: inline-block;
			margin-left: 8px;
		}
	}
}

.js-lazy-load:not(.is-loaded) {
	max-height: 50vh;
}

.container {
	position: relative;
	z-index: 1;
	margin: 0 auto;
	width: 100%;
	max-width: 100%;
	padding-left: 1rem;
	padding-right: 1rem;

	@include desktop {
		max-width: 1296px;
	}
}

.site {
	display: flex;
	flex-direction: column;
	min-height: 100vh; // fallback
	// stylelint-disable-next-line
	min-height: var(--vh);
	overflow: hidden;
}

.title {
	margin: 0;
	font-weight: 700;
	font-size: 41px;
	line-height: 47px;
	color: #000;

	&:not(:last-child) {
		margin-bottom: 6px;
	}

	@include desktop {
		font-size: 52px;
		line-height: 60px;
	}
}

.subtitle {
	font-size: 16px;
	line-height: 30px;
	color: #000;

	@include desktop {
		font-size: 20px;
		line-height: 32px;
	}
}

.description {
	display: block;
	font-size: 13px;
	line-height: 20px;
	color: rgba($color: #000000, $alpha: 0.4);

	&:not(:last-child) {
		margin-bottom: 6px;
	}

	@include desktop {
		font-size: 16px;
		line-height: 24px;
	}
}

.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right !important;
}

.squares {
	background: url("../images/content/squares.svg");
}

.main-bg {
	position: relative;
	overflow: hidden;
	&::after {
		content: "";
		position: fixed;
		top: 50%;
		left: -18px;
		width: 241px;
		height: 624px;
		border-radius: 40px;
		background: #e0b9e2;
		opacity: 0.05;
		z-index: -1;

		@include tablet {
			width: 467px;
			height: 624px;
		}

		@include desktop {
			width: 852px;
			height: 751px;
		}
	}

	&::before {
		content: "";
		position: fixed;
		top: 20px;
		right: -25px;
		width: 144px;
		height: 940px;
		border-radius: 40px;
		background: #65b2ef;
		opacity: 0.05;
		z-index: -1;

		@include tablet {
			right: -370px;
			width: 536px;
			height: 1520px;
		}

		@include desktop {
			right: -27px;
		}
	}
}

.swiper-pagination {
	margin-top: 30px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	&-bullet {
		width: 18px;
		height: 18px;
		display: inline-block;
		border-radius: 50%;
		cursor: pointer;
		background-color: rgba($color: #000000, $alpha: 0.2);
		transition: all 300ms ease-in-out;

		&:not(:last-child) {
			margin-right: 3px;
		}

		&-active {
			width: 30px;
			border-radius: 10px;
			background-color: #e0b9e2;
			transition: all 300ms ease-in-out;
		}
	}
}

.swiper-button-prev,
.swiper-button-next {
	position: absolute;
	top: 50%;
	width: 96px;
	height: 96px;
	border-radius: 50%;
	background: linear-gradient(
		315deg,
		#e8b7e5 0%,
		#a25fd5 48.93%,
		#6bbbf5 100%
	);
	transform: translateY(-50%);
	cursor: pointer;
	z-index: 2;

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 51px;
		height: 43px;
		fill: #fff;
		transform: translate(-50%, -50%);
	}
}

.swiper-button-prev {
	left: -48px;
}

.swiper-button-next {
	right: -48px;
}

.default-section {
	ul {
		margin: 1rem 0;
		li {
			position: relative;
			padding-left: 40px;
			font-weight: 600;
			font-size: 20px;
			line-height: 32px;
			color: #000;

			&:not(:last-child) {
				margin-bottom: 8px;
			}

			&::after {
				content: "";
				position: absolute;
				top: 5px;
				left: 0;
				width: 23px;
				height: 23px;
				background: url("../images/icons/loaded.svg") no-repeat center;
			}
		}
	}
}

.half-block {
	@include tablet {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}

	@include desktop {
		max-width: 490px;
	}
}

.logo-bg {
	position: relative;

	&::after {
		content: "";
		position: fixed;
		top: 50px;
		left: -28px;
		width: 561.997px;
		height: 562px;
		opacity: 0.05;
		background: url("../images/icons/logo-big.svg") no-repeat center 100%/100%;
		z-index: -1;

		@include tablet {
			width: 1082px;
			height: 1082px;
			left: -127px;
		}

		@include desktop {
			left: 0;
			right: 0;
			margin: auto;
		}
	}
}

/* stylelint-disable */
/*
* Немедленно переместите любую анимацию в конечную точку, если пользователь установил свое устройство в положение "prefers reduced motion".
* Это может привести к плохим(непреднамеренным) последствиям. Удалите по мере необходимости и напишите свой собственный код для prefers-reduced-motion.
*/
@media (prefers-reduced-motion: reduce) {
	*,
	*:before,
	*:after {
		animation-duration: 0.001s !important;
		animation-delay: 0s !important;
		transition-duration: 0.001s !important;
		transition-delay: 0s !important;
	}
}
/* stylelint-enable */
