.footer {
	display: flex;
	margin-top: auto;
	padding: 73px 0;
	width: 100%;
	background-color: rgba($color: #000000, $alpha: 0.89);

	@include tablet {
		padding-bottom: 30px;
	}

	&__logo {
		display: none;

		img {
			display: block;
			width: 100%;
			height: auto;
		}

		@include tablet {
			display: inline-block;
			margin-right: 50px;
			width: 80px;
			height: 80px;
			flex: 0 0 80px;
		}

		@include desktop {
			width: 108px;
			height: 108px;
			flex: 0 0 108px;
		}
	}

	&__top {
		display: flex;
		align-items: flex-start;
		margin-bottom: 75px;
	}

	&__bottom {
		@include tablet {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&__menu {
		width: 100%;
		flex: 0 1 100%;

		@include tablet {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 30px;
		}

		@include desktop {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&__block {
		display: flex;
		align-items: flex-start;
		flex-direction: column;

		@include tablet {
			position: relative;

			&:not(:nth-child(3n-3)) {
				padding-right: 30px;
				border-right: 1px solid rgba($color: #fff, $alpha: 0.28);
			}
		}

		@include desktop {
			&:not(:nth-child(3n-3)) {
				padding-right: 0;
				border: none;
			}
			&:not(:nth-child(4n-4)) {
				padding-right: 30px;
				border-right: 1px solid rgba($color: #fff, $alpha: 0.28);
			}
		}
	}

	&__link {
		font-size: 26px;
		font-weight: 600;
		line-height: 36px;
		color: #fff;

		@include desktop {
			font-size: 41px;
			line-height: 47px;
		}

		@include hover {
			color: #9962ce;
		}
	}

	&__dev {
		font-size: 20px;
		font-weight: 600;
		line-height: 23px;
		color: #fff;
	}

	&__copy {
		font-size: 20px;
		font-weight: 400;
		line-height: 32px;
		color: #9962ce;
	}
}
