.gallery-block {
	position: relative;
	width: 100%;
	min-height: 664px;
	border-radius: 40px;
	overflow: hidden;
	background-color: #e0b9e2;
	box-shadow: 0px 4px 23px 5px rgba(0, 0, 0, 0.25) inset;

	@include tablet {
		min-height: 440px;
	}

	@include desktop {
		min-height: 551px;
	}

	@include hover {
		.gallery-block__image {
			transform: scale(1.05);
			transition-duration: 800ms;
		}
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		transition: transform 600ms cubic-bezier(0.35, 0, 0, 1) 0s;
		will-change: transform;
	}

	&__title {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 80%;
		font-weight: 600;
		font-size: 52px;
		line-height: 60px;
		color: #fffbfb;
		text-align: center;
		transform: translate(-50%, -50%);
		z-index: 2;

		@include tablet {
			width: 50%;
		}
	}

	&__button {
		position: absolute;
		bottom: 21px;
		left: 23px;

		@include tablet {
			bottom: 20px;
			left: 20px;
		}

		@include desktop {
			bottom: 25px;
			left: 25px;
		}
	}

	&--half {
		.gallery-block__title {
			@include tablet {
				width: 90%;
			}
		}
	}
}

.gallery-row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -6.5px;

	@include desktop {
		margin: 0 -9px;
	}
}

.gallery-col {
	max-width: 100%;
	flex: 0 0 100%;
	padding: 0 6.5px;
	margin-bottom: 15px;

	@include tablet {
		margin-bottom: 10px;
	}

	@include desktop {
		margin-bottom: 1rem;
	}

	@include desktop {
		padding: 0 9px;
	}

	&--7 {
		@include tablet {
			max-width: 50%;
			flex: 0 0 50%;
		}

		@include desktop {
			max-width: 60%;
			flex: 0 0 60%;
		}
	}

	&--5 {
		@include tablet {
			max-width: 50%;
			flex: 0 0 50%;
		}

		@include desktop {
			max-width: 40%;
			flex: 0 0 40%;
		}
	}
}

.gallery-grid {
	display: grid;
	grid-gap: 1rem;
	@include tablet {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	@media (min-width: ($tablet + "px")) and (max-width: ($desktop + "px")) {
		&-column-1-3 {
			grid-column: 1/3;
		}
	}

	@include desktop {
		&-row-1-3 {
			grid-row: 1/3;
		}
	}

	.gallery-block__title {
		@include tablet {
			width: 70%;
		}
	}
}

.gallery-text {
	display: none;
	@include desktop {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding-left: 100px;
	}
}
