.persons {
	&__block {
		position: relative;
		width: 100%;
		height: auto;
		aspect-ratio: 1;
		border-radius: 40px;
		overflow: hidden;

		&:not(:last-child) {
			margin-right: 1rem;
			@include tablet {
				margin-right: 0;
				margin-bottom: 1rem;
			}
		}
	}

	&-slider {
		display: none;

		@include tablet {
			display: block;
		}

		.swiper-wrapper {
			width: calc(100% + 1rem);

			@include tablet {
				width: 100%;
			}
		}

		.swiper-slide {
			width: auto;
		}

		.swiper-pagination {
			@include desktop {
				display: none;
			}
		}

		&--mobile {
			@include tablet {
				display: none;
			}
		}
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	&__col {
		display: flex;
		flex-direction: row;

		@include tablet {
			flex-direction: column;
		}
		&--margin {
			@include desktop {
				margin-top: 120px;
			}
		}
	}

	&__body {
		position: absolute;
		bottom: 24px;
		left: 31px;
		right: 31px;
		z-index: 2;
		color: #fff;
	}

	&__name {
		font-size: 20px;
		font-weight: 600;
		line-height: 23px;
	}
	&__dir {
		font-weight: 400;
		line-height: 20px;
		color: rgba($color: #fff, $alpha: 0.7);
	}
}
