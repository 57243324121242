.socials {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	grid-gap: 62px;

	&__link {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		height: 80px;
		filter: grayscale(1);
		opacity: 0.4;
		transition: all 0.3s ease-in-out;

		@include hover {
			opacity: 1;
			filter: grayscale(0);
		}
	}

	&__icon {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
	}
}
