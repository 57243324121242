.creators-block {
	position: relative;
	display: block;
	border-radius: 40px;
	overflow: hidden;
	@include hover {
		.creators-block__image {
			transform: scale(1.05);
			transition-duration: 800ms;
		}
	}

	&__image {
		display: block;
		border-radius: 40px;
		object-fit: cover;
		object-position: center;
		overflow: hidden;
		transition: transform 600ms cubic-bezier(0.35, 0, 0, 1) 0s;
		will-change: transform;
	}

	&__body {
		position: absolute;
		left: 26px;
		right: 26px;
		bottom: 26px;
	}

	&__name {
		font-size: 16px;
		line-height: 24px;
		color: #fff;

		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	&__socials {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		& > * {
			margin-right: 17px;
		}
	}

	&__social {
		width: 27px;
		height: 27px;
	}
}

.circle {
	display: inline-block;
	width: 24px;
	height: 24px;
	background-color: #b3b3b3;
	border-radius: 50%;
}

.triangle {
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 24px 12px 0 12px;
	border-color: #b3b3b3 transparent transparent transparent;
}

.square {
	display: inline-block;
	width: 24px;
	height: 24px;
	background-color: #b3b3b3;
}
