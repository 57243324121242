// stylelint-disable scss/dollar-variable-empty-line-before

// COLORS
$color-black: #000;
$color-white: #fff;
$color-gray: #eee;

// FONTS
// stylelint-disable-next-line
$font-primary: "Poppins", sans-serif;
$font-secondary: "Inter", sans-serif;

// EASING TRANSITIONS
$duration-normal: 0.4s;
$easing-back-out: cubic-bezier(
	0.25,
	0.74,
	0.22,
	1.1
); // http://cubic-bezier.com/#.25,.74,.22,1.1
$easing-back-in: cubic-bezier(
	0.47,
	-0.1,
	0.5,
	-0.06
); // http://cubic-bezier.com/#.47,-0.1,.5,-.06
$easing-out: ease-out;
$easing-in: ease-in;

// Settings
$desktop: 1024;
$desktop-xl: 1800;
$laptop: 1440;
$tablet: 744;
$mobile: 320;
$desktop-vh: 750;
