.work {
	margin-top: 120px;

	@include tablet {
		margin-top: 192px;
	}

	@include desktop {
		margin-top: 248px;
	}

	&-services {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 65px;

		@include tablet {
			margin-bottom: 40px;
		}

		@include desktop {
			flex-direction: row;
			align-items: center;
			margin-bottom: 160px;
		}

		&__block {
			@include desktop {
				width: 100%;
				flex: 0 1 100%;
			}
		}
	}

	&-slider {
		width: 100%;
		margin-bottom: 40px;

		@include desktop {
			margin-right: 125px;
			width: 640px;
			flex: 0 0 640px;
		}

		&__slide {
			width: 100%;
			height: 551px;
			border-radius: 40px;
			background-color: #e0b9e2;
			overflow: hidden;
		}

		&__image {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		.swiper-pagination {
			@include desktop {
				display: none;
			}
		}
	}

	&-content {
		margin-top: 65px;
		margin-bottom: 50px;

		@include desktop {
			margin-top: 160px;
			margin-bottom: 120px;
		}
	}

	&-row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -41px;

		@include tablet {
			align-items: center;
		}
	}

	&-lg-reversed {
		@include desktop {
			flex-direction: row-reverse;
		}
	}

	&-margin {
		margin-bottom: 90px;
	}

	&-col {
		width: 100%;
		flex: 0 0 100%;
		padding: 0 41px;

		@include desktop {
			width: 50%;
			flex: 0 0 50%;
		}
	}

	&-image {
		width: 100%;
		height: 436px;
		border-radius: 40px;
		overflow: hidden;
		background-color: #e0b9e2;

		@include tablet {
			height: 381px;
		}

		@include desktop {
			height: 551px;
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}
