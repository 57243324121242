.careers {
	margin-top: 104px;

	@include desktop {
		margin-top: 144px;
	}

	&__header {
		margin-bottom: 80px;

		@include desktop {
			margin-bottom: 60px;
		}
	}

	&__slider {
		margin: 80px 1rem 72px;
		@include tablet {
			margin-left: 0;
			margin-right: 0;
			margin-bottom: 50px;
			.swiper-slide {
				width: auto !important;
			}
		}

		@include desktop {
			margin-bottom: 200px;
		}

		.swiper-pagination {
			justify-content: center;
			margin-left: 1rem;
			margin-right: 1rem;
		}
	}

	&__slide {
		border-radius: 40px;
		background-color: #e0b9e2;
		overflow: hidden;

		@include tablet {
			width: 532px;
		}

		@include desktop {
			width: 858px;
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&-dropdowns {
		margin-top: 72px;
		margin-bottom: 105px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex-wrap: wrap;

		@include desktop {
			flex-direction: row;
			justify-content: center;
		}
	}

	&-dropdown {
		padding: 1rem 20px 2rem;
		border-bottom: 1px solid #e0b9e2;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}

		@include tablet {
			max-width: 400px;
			margin-left: auto;
			margin-right: auto;
		}

		@include desktop {
			margin-left: inherit;
			margin-right: inherit;
			border: none;
			pointer-events: none;
		}

		&__header {
			margin-bottom: 24px;
		}

		&__number {
			margin: 0 auto;
			width: 64px;
			height: 64px;
			font-weight: 600;
			font-size: 32px;
			line-height: 64px;
			color: #fff;
			text-align: center;
			border-radius: 50%;
			background-color: #e0b9e2;
		}

		&__title {
			font-weight: 700;
			font-size: 41px;
			line-height: 47px;
			color: #000;
		}

		&__subtitle {
			margin-top: 6px;
			font-weight: 400;
			font-size: 16px;
			line-height: 30px;
			display: none;

			@include desktop {
				display: block;
			}
		}

		&__plus {
			position: relative;
			width: 38px;
			height: 38px;
			margin: 32px auto 0;

			@include desktop {
				display: none;
			}

			&::after,
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 4px;
				margin: auto;
				border-radius: 10px;
				background-color: #e0b9e2;
				transition: all 0.3s ease-in-out;
			}

			&::after {
				transform: rotate(90deg);
			}
		}

		@include active {
			.careers-dropdown__plus {
				&::after {
					transform: rotate(0);
				}
			}
		}
	}

	&-section {
		position: relative;
		margin: 105px 0;

		@include desktop {
			margin: 200px 0;
			min-height: 400px;
		}

		.container {
			position: unset;
		}

		.swiper-pagination {
			@include desktop {
				display: none;
			}
		}
	}

	&-block {
		margin-top: 90px;
		margin-bottom: 128px;

		@include tablet {
			margin-top: 105px;
			margin-bottom: 140px;
		}

		@include desktop {
			margin-top: 226px;
			margin-bottom: 230px;
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			@include tablet {
				max-width: 400px;
				margin-left: auto;
				margin-right: auto;
			}

			@include desktop {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 125px;
				align-items: center;
				max-width: 70%;
			}
		}

		&__title {
			margin-bottom: 50px;
			text-align: center;
		}

		&__lists {
			@include tablet {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
			}

			@include desktop {
				grid-template-columns: repeat(3, 1fr);
			}
		}

		&__list {
			position: relative;
			font-weight: 400;
			font-size: 16px;
			line-height: 30px;
			color: #000;
			padding-left: 60px;
			margin-bottom: 30px;
			margin-top: 30px;

			&::after {
				content: "";
				position: absolute;
				top: -10px;
				left: 0;
				width: 46px;
				height: 46px;
				background: url("../images/icons/check.svg") no-repeat center;
			}
		}
	}

	&-openings {
		margin: 128px 0;

		@include desktop {
			margin: 230px 0;
		}

		&__wrapper {
			@include tablet {
				max-width: 530px;
				margin-left: auto;
				margin-right: auto;
			}

			@include desktop {
				max-width: 858px;
			}
		}

		&__title {
			&:not(:last-child) {
				margin-bottom: 40px;
			}
		}
	}

	&-playing {
		margin-top: 215px;
		margin-bottom: 100px;

		@include tablet {
			margin-top: 173px;
			margin-bottom: 164px;
		}

		@include desktop {
			margin-bottom: 160px;
		}

		&__title {
			&:not(:last-child) {
				margin-bottom: 80px;
			}

			@include desktop {
				max-width: 540px;
			}
		}

		&__block {
			margin-bottom: 40px;
		}

		&__image {
			width: 100%;
			height: auto;
			aspect-ratio: 1;
			border-radius: 40px;
			overflow: hidden;
			background-color: #e0b9e2;

			&:not(:last-child) {
				margin-bottom: 2rem;
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		&__footer {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		&__avatar {
			width: 62px;
			height: 62px;
			flex: 0 0 62px;
			margin-right: 18px;
		}

		&__text {
			flex: 1 1 auto;
			font-size: 16px;
			line-height: 30px;
		}

		&__name {
			font-weight: 700;
		}

		&__desc {
			font-weight: 400;
		}
	}

	&-row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -8px;

		& > * {
			padding: 0 8px;
		}
	}

	&-col {
		max-width: 100%;
		flex: 0 0 100%;

		@include tablet {
			max-width: 50%;
			flex: 0 0 50%;
		}

		@include desktop {
			max-width: 25%;
			flex: 0 0 25%;
		}
	}

	&-about {
		margin: 100px 0;
		@include desktop {
			margin: 160px 0;
		}
	}

	.half-block {
		@include tablet {
			max-width: none;
		}
		@include desktop {
			max-width: 731px;
		}
	}
}

.openings {
	&__filters {
		margin-bottom: 75px;
		@media (max-width: ($tablet + "px")) {
			flex-direction: column;
			align-items: stretch;

			.search-filter {
				padding-left: 0;
				padding-right: 0;

				&::after {
					margin-left: auto;
				}
			}
		}

		@include desktop {
			margin-bottom: 88px;
		}
	}

	&__item {
		&:not(:last-child) {
			margin-bottom: 80px;
		}

		&-header {
			margin-bottom: 31px;
			font-weight: 400;
			font-size: 16px;
			line-height: 30px;
			@include desktop {
				font-size: 20px;
				line-height: 32px;
			}
		}

		&-footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&-time,
		&-location {
			font-weight: 400;
			font-size: 16px;
			line-height: 30px;
			font-weight: 700;
		}
	}
}
