.header {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	z-index: 2100;
	display: flex;
	align-items: center;
	padding-top: 45px;

	@include desktop {
		padding-top: 40px;
	}

	&__logo {
		position: relative;
		display: block;
		width: 87px;
		height: 30px;
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 1rem;

		@include laptop {
			padding: 0;
		}
	}

	&__burger {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 30px;
		padding: 0;
		border: none;
		background: transparent;

		@include tablet {
			z-index: 0;
		}

		span {
			position: absolute;
			left: 0;
			width: 100%;
			height: 4px;
			margin: auto;
			display: block;
			overflow: hidden;
			border-radius: 90px;
			background-color: #fff;
			transition: all 0.3s ease-in-out;
			&:nth-child(1) {
				top: 0;
			}

			&:nth-child(2) {
				top: 0;
				bottom: 0;
			}

			&:nth-child(3) {
				bottom: 0;
			}
		}

		@include active {
			span {
				background-color: #000;
				transition: all 0.3s ease-in-out;
				&:nth-child(1) {
					bottom: 0;
					transform: rotate(45deg);
				}

				&:nth-child(2) {
					opacity: 0;
				}

				&:nth-child(3) {
					top: 0;
					transform: rotate(-45deg);
				}
			}
		}

		@include desktop {
			display: none;
		}
	}

	&__link {
		padding: 5px 0;
		font-size: 16px;
		line-height: 30px;
		color: #000;

		@include desktop {
			padding: 0;
			font-size: 16px;
			line-height: 24px;
			color: #fff;

			&:not(:last-child) {
				margin-right: 43px;
			}
		}
	}

	&__menu {
		position: fixed;
		top: 34px;
		right: 40px;
		width: max-content;
		height: max-content;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background-color: #fff;
		padding: 16px 24px;
		overflow-x: hidden;
		overflow-y: auto;
		border-radius: 10px;
		z-index: 1024;
		opacity: 0;
		visibility: hidden;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		transition: 0.3s ease-in-out;

		@include active {
			opacity: 1;
			visibility: visible;
		}

		@include desktop {
			position: unset;
			padding-top: 0;
			overflow: initial;
			display: block;
			width: auto;
			background: transparent;
			opacity: 1;
			visibility: visible;
			box-shadow: none;
		}
	}

	&__list {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		flex-direction: column;

		@include desktop {
			flex-direction: row;
		}
	}
}

.no--hero {
	.header__link {
		color: #9962ce;
	}

	.header__burger span {
		background: linear-gradient(
			315deg,
			#e8b7e5 0%,
			#a25fd5 48.93%,
			#6bbbf5 100%
		);
	}
}
