.about {
	margin-top: 145px;
	@include tablet {
		margin-top: 216px;
	}

	@include desktop {
		margin-top: 192px;
	}

	&__squares {
		width: 100%;
		height: 104px;
		margin: 0 auto 94px;

		@include tablet {
			margin-bottom: 100px;
			width: 400px;
		}

		@include desktop {
			margin-bottom: 80px;
		}
	}

	&-ecosystem {
		margin-top: 100px;
		margin-bottom: 100px;
		position: relative;

		@include desktop {
			margin-top: 200px;
			margin-bottom: 200px;

			&::after {
				content: "";
				position: absolute;
				top: -30px;
				left: -145px;
				width: 217px;
				height: 543px;
				border-radius: 40px;
				background: linear-gradient(
					360deg,
					#e8b7e5 0%,
					#a25fd5 48.93%,
					#6bbbf5 100%
				);
			}
		}
	}

	&-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 100px;

		@include desktop {
			grid-template-columns: 539px 1fr;
			grid-gap: 78px;
		}
	}

	&-dropdown {
		&__items {
			margin-top: 22px;
		}

		&__item {
			border-bottom: 1px solid #e0b9e2;

			&:first-child {
				border-top: 1px solid #e0b9e2;
			}

			@include active {
				.about-dropdown__header {
					&::before {
						transform: rotate(0);
					}
				}
			}
		}

		&__header {
			padding: 20px 0;
			font-weight: 700;
			font-size: 16px;
			line-height: 30px;
			color: #000;
			position: relative;
			cursor: pointer;
			transition: all 0.3s ease-in-out;

			@include hover {
				color: #a25fd5;
			}

			&::after,
			&::before {
				content: "";
				position: absolute;
				top: 33px;
				right: 21px;
				width: 32px;
				height: 4px;
				background: linear-gradient(
					315deg,
					#e8b7e5 0%,
					#a25fd5 48.93%,
					#6bbbf5 100%
				);
				border-radius: 10px;
				transition: all 0.3s ease-in-out;
			}

			&::before {
				transform: rotate(90deg);
			}
		}

		&__body {
			padding-bottom: 20px;
			font-weight: 400;
			font-size: 20px;
			line-height: 32px;
			color: #000;
			display: none;
		}

		&__link {
			display: block;
			margin-top: 10px;
			font-weight: 700;
			font-size: 20px;
			line-height: 32px;
			color: #000;
			width: max-content;
			text-transform: uppercase;

			@include hover {
				color: #a25fd5;
			}
		}
	}

	&-leadership {
		position: relative;
		margin: 100px 0;
		@include desktop {
			margin-bottom: 200px;
		}

		&--bg {
			position: relative;
			&::after {
				content: "";
				position: absolute;
				top: 200px;
				left: -98px;
				width: 305px;
				height: 752px;
				border-radius: 40px;
				background: rgba($color: #e0b9e2, $alpha: 0.05);

				@include tablet {
					top: 180px;
					left: -294px;
					width: 749px;
					height: 1133px;
				}

				@include desktop {
					top: 240px;
					left: -146px;
					height: 1596px;
				}
			}

			&::before {
				content: "";
				position: absolute;
				top: 160px;
				right: -85px;
				width: 292px;
				height: 529px;
				border-radius: 40px;
				background: rgba($color: #65b2ef, $alpha: 0.05);

				@include tablet {
					top: 530px;
					right: -271px;
					width: 453px;
					height: 529px;
				}

				@include desktop {
					top: 500px;
					right: -69px;
				}
			}
		}

		&__header {
			margin-bottom: 50px;
			@include tablet {
				max-width: 400px;
				margin-left: auto;
				margin-right: auto;
			}
			@include desktop {
				max-width: 486px;
				margin-bottom: 190px;
			}
		}
	}

	&-text-block {
		margin-bottom: 90px;
		@include tablet {
			max-width: 400px;
			margin-left: auto;
			margin-right: auto;
		}

		@include desktop {
			max-width: 530px;
			margin-bottom: 140px;
		}

		&__button {
			margin-top: 50px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
